<template>
  <v-card flat tile>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat color="white">
      <v-toolbar-title>
        <v-icon class="mb-1">mdi-airplane-edit</v-icon>
        INGRESAR NUEVA RESERVA
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="buscar"
        dense
        label="filtrar"
        class="mx-4"
        flat
        clearable
      >
      </v-text-field>

      <v-spacer></v-spacer>
    </v-toolbar>
    <div>
      <v-data-table
        :headers="headerGuiasCoord"
        :items="guiasCoord"
        item-key="AWB"
        :search="buscar"
        class="elevation-1"
        show-expand
        :single-expand="true"
        :expanded.sync="reservaexp"
      >
        <template v-slot:item.FECHA="{ item }">
          {{ formatoFecha(item.FECHA) }}
        </template>

        <template v-slot:item.CLIENTE="{ item }">
          {{ item.CLIENTE }} {{ item.MARCACION }}
        </template>

        <template v-slot:item.DEST_PAIS="{ item }">
          {{ item.DEST_NOMBRE }}
        </template>

        <template v-slot:item.params_coord="{ item }">
          <span v-for="i in item.params_coord">
            <v-chip
              v-if="i.TIPO_PARAM == 'VARIEDAD'"
              small
              class="ma-1"
              :color="colorVariedad(i.VALOR_PARAM)"
            >
              {{ i.VALOR_PARAM }}
            </v-chip>
          </span>
        </template>

        <template v-slot:item.opciones="{ item }">
          <v-btn
            color="success"
            icon
            small
            text
            @click="reservar(item)"
            v-if="item.reservas.length <= 1"
          >
            <v-icon color="success">mdi-plus</v-icon>
            Reservar
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12" class="my-2">
                <v-alert type="success" dense class="mb-0 py-1">
                  Datos de la reserva
                </v-alert>

                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">Guia</th>
                        <th class="text-center">MAWB</th>
                        <th class="text-center">Piezas</th>
                        <th class="text-center">Full</th>
                        <th class="text-center">Medio</th>
                        <th class="text-center">Tercio</th>
                        <th class="text-center">Cuarto</th>
                        <th class="text-center">Sexto</th>
                        <th class="text-center">Octavo</th>
                        <th class="text-center">Diecis.</th>
                        <th class="text-center">DAE</th>
                        <th class="text-center">Opciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="reserva in item.reservas"
                        :key="reserva.NUM_GUIA_FINCA"
                      >
                        <td class="text-center">
                          {{ reserva.NUM_GUIA_FINCA }}
                        </td>
                        <td class="text-center">{{ item.AWB }}</td>
                        <td class="text-center">{{ reserva.PIEZAS_CONF }}</td>
                        <td class="text-center">{{ reserva.FU }}</td>
                        <td class="text-center">{{ reserva.MD }}</td>
                        <td class="text-center">{{ reserva.TH }}</td>
                        <td class="text-center">{{ reserva.CU }}</td>
                        <td class="text-center">{{ reserva.SX }}</td>
                        <td class="text-center">{{ reserva.OC }}</td>
                        <td class="text-center">{{ reserva.SXT }}</td>
                        <td class="text-center">{{ reserva.FUE_FACTURA }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mr-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cargarReserva(reserva)"
                                color="info"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <span>Editar reserva</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-1"
                                v-bind="attrs"
                                v-on="on"
                                @click="cancelar(reserva)"
                                color="error"
                                >mdi-cancel</v-icon
                              >
                            </template>
                            <span>Cancelar reserva</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <!-- FORM RESERVA-->
      <v-dialog v-model="dialogReserva" persistent max-width="660px">
        <v-card>
          <v-card-title>
            <v-icon class="mx-2">mdi-airplane-edit</v-icon>
            <span class="headline"
              >Reserva
              <span style="font-size: 11pt; font-weight: bold"
                >| {{ formatoFecha(reserva.FECHA) }}</span
              ></span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="formReserva">
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-autocomplete
                      :rules="_fechaDae"
                      :disabled="soloVer || esGuiaEncargos === false"
                      v-model="reserva.FUE_ID"
                      small
                      item-text="DAE_DEST"
                      item-value="FUE_ID"
                      :items="daes"
                      clearable
                      label="Seleccione una DAE"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.FUE"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.DESTINO"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="px-1 py-5" cols="14">
                    <v-label class="px-12 py-4">Variedades</v-label>
                    <span v-for="i in reserva.VARIEDADES">
                      <v-chip
                        small
                        class="ma-1"
                        :color="colorVariedad(i.VALOR_PARAM)"
                      >
                        {{ i.VALOR_PARAM }}
                      </v-chip>
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.fulles"
                      label="Fulles"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.mitades"
                      label="Mitades"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.tercios"
                      label="Tercios"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.cuartos"
                      label="Cuartos"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.sextos"
                      label="Sextos"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="1.5">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.octavos"
                      label="Octavos"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pt-0 pb-0" cols="2">
                    <v-text-field
                      :disabled="soloVer"
                      v-model="reserva.dieciseisavos"
                      label="Dieciseisavos"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="12">
                    <v-textarea
                      :disabled="soloVer"
                      rows="2"
                      v-model="reserva.OBSERVACIONES"
                      label="Observaciones"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-center">
              <v-btn
                v-if="editarRes == false"
                color="info"
                small
                @click="guardarReserva()"
              >
                <v-icon>mdi-content-save</v-icon> Guardar
              </v-btn>
              &nbsp;
              <v-btn
                small
                v-if="editarRes == true"
                color="info"
                @click="modificarReserva()"
              >
                <v-icon>mdi-content-save</v-icon> Guardar
              </v-btn>
              &nbsp;
              <v-btn small @click="dialogReserva = false">
                <v-icon>mdi-cancel</v-icon> Cancelar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ReservasFincaComponent",

  data: () => ({
    headerGuiasCoord: [
      { text: "Destino", align: "start", value: "DEST_PAIS" },
      { text: "Fecha de vuelo", align: "start", value: "FECHA" },
      { text: "Cliente", align: "start", value: "CLIENTE" },
      { text: "Opciones", value: "params_coord" },
      { text: "Estado", align: "start", value: "GUIA_ESTADO" },
      { text: "Reservas", value: "data-table-expand", align: "center" },
      { text: "Opciones", align: "start", value: "opciones" },
    ],
    guiasCoord: [],
    destinos: [],
    daes: [],
    reserva: {
      VARIEDADES: [],
      FUE_ID: "",
      CABECERA_ID: "",
      FINCA_ID: "",
      HCOORD_ID: "",
      CNEE_ID: "",
      RATE: "",
      AWB: "",
      FECHA: "",
      COMER_ID: "",
      SCLIENTE_ID: "",
      full: "0",
      mitades: "0",
      tercios: "0",
      cuartos: "0",
      sextos: "0",
      octavos: "0",
      dieciseisavos: "0",
      OBSERVACIONES: "",
    },
    reservaexp: [],
    buscar: "",
    destinoId: "",
    cabeceraId: "",
    overlay: false,
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 10,
    dialogReserva: false,
    editarRes: false,
    soloVer: false,
    variedades: [
      { var: "ROSAS", color: "#ff3471" },
      { var: "FLOR DE VERANO", color: "#2267FF" },
    ],
    requiredRule: [
      (v) =>
        (v != "" && v != null && v != undefined) || "El campo es requerido",
    ],
  }),
  watch: {},
  computed: {
    ...mapState("master", ["user", "loadingTable"]),

    ...mapState("access", ["loggedIn"]),
    esGuiaEncargos() {
      let res = false;
      if (
        !(
          this.reserva.AWB.includes("EZF") === true ||
          this.reserva.AWB.includes("FWC") === true ||
          this.reserva.AWB.includes("MAR") === true
        )
      )
        res = true;

      return res;
    },
    _fechaDae() {
      let rf = this.reserva.FECHA;

      if (this.reserva.FUE_ID == "" || this.reserva.FUE_ID == null) {
        if (
          !(
            this.reserva.AWB.includes("EZF") === true ||
            this.reserva.AWB.includes("FWC") === true ||
            this.reserva.AWB.includes("MAR") === true
          )
        )
          return [false || "Seleccione una DAE "];
      }
      for (let i = 0; i < this.daes.length; i++) {
        if (this.daes[i].FUE_ID == this.reserva.FUE_ID) {
          if (
            !moment(rf).isBetween(
              moment(this.daes[i].FECHA_INICIO),
              moment(this.daes[i].FECHA_CADUCA),
              undefined,
              "[]"
            )
          ) {
            return [
              false ||
                "La DAE seleccionada no se puede utilizar para esta fecha de vuelo",
            ];
          }
        }
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingBtn",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    handlePageChange(value) {
      //  this.currentPage = value;
      //this.getDestinos();
    },
    formatoFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("ddd D-MMM-Y");
    },
    colorVariedad(x) {
      for (let i = 0; i <= this.variedades.length; i++) {
        if (this.variedades[i].var == x) {
          return this.variedades[i].color;
        }
      }
    },
    getDestinos() {
      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("api/destinos-coord");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
        },
      })
        .then((res) => {
          this.destinos = res.data.destinos;
          this.daes = res.data.fues;
          console.log(this.daes);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    reservar(item) {
      this.editarRes = false;
      //console.log(item.params_coord[0])
      this.reserva = {
        VARIEDADES: item.params_coord,
        FUE_ID: item.FUE_ID,
        CABECERA_ID: item.CABECERA_ID,
        FINCA_ID: this.user.empresa_id,
        HCOORD_ID: item.HCOORD_ID,
        CNEE_ID: item.CLIENTE_ID,
        RATE: item.RECARGO_COMBUS,
        AWB: item.AWB,
        FECHA: item.FECHA,
        COMER_ID: this.user.empresa_id,
        SCLIENTE_ID: item.CLIENTE_ID,
        fulles: 0,
        mitades: 0,
        tercios: 0,
        cuartos: 0,
        sextos: 0,
        octavos: 0,
        dieciseisavos: 0,
        OBSERVACIONES: "",
      };

      this.dialogReserva = true;
    },

    cargarReserva(item) {
      this.editarRes = true;
      this.soloVer = false;
      this.setUrl("api/cargar-reserva");
      this.requestApi({
        method: "GET",
        data: {
          detalle_id: item.DETALLE_ID,
        },
      })
        .then((res) => {
          console.log(res.data.reserva);
          this.reserva = {
            DETALLE_ID: res.data.reserva.DETALLE_ID,
            VARIEDADES: item.params_coord,
            FUE_ID: res.data.reserva.FUE_ID,
            CABECERA_ID: res.data.reserva.CABECERA_ID,
            FINCA_ID: res.data.reserva.FINCA_ID,
            HCOORD_ID: item.HCOORD_ID,
            CNEE_ID: res.data.reserva.CLIENTE_ID,
            RATE: res.data.reserva.RECARGO_COMBUS,
            AWB: res.data.reserva.AWB,
            FECHA: res.data.reserva.FECHA,
            COMER_ID: res.data.reserva.COMER_ID,
            SCLIENTE_ID: res.data.reserva.SCLIENTE_ID,
            fulles: res.data.reserva.fulles,
            mitades: res.data.reserva.mitades,
            tercios: res.data.reserva.tercios,
            cuartos: res.data.reserva.cuartos,
            sextos: res.data.reserva.sextos,
            octavos: res.data.reserva.octavos,
            dieciseisavos: res.data.reserva.dieciseisavos,
            OBSERVACIONES: res.data.reserva.OBSERVACIONES,
          };
          this.dialogReserva = true;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarGuias() {
      if (this.destinoId == null) return;
      this.setLoadingTable(true);
      console.log(this.destinoId.DEST_CIUDAD);

      this.setUrl("api/guias-coord");
      this.requestApi({
        method: "GET",
        data: {
          destino: this.destinoId.DEST_CIUDAD,
          page: this.currentPage,
        },
      })
        .then((res) => {
          this.guiasCoord = res.data.guias;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarReserva() {
      if (!this.$refs.formReserva.validate()) {
        return;
      }

      this.dialogReserva = false;

      this.setUrl("api/crear-reserva");
      this.requestApi({
        method: "POST",
        data: this.reserva,
      })
        .then((res) => {
          //this.guiasCoord  = res.data.guias
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarGuias();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarReserva() {
      if (!this.$refs.formReserva.validate()) {
        return;
      }

      this.dialogReserva = false;

      this.setUrl("api/modificar-reserva");
      this.requestApi({
        method: "POST",
        data: this.reserva,
      })
        .then((res) => {
          //this.guiasCoord = res.data.guias
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarGuias();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cancelar(item) {
      Vue.swal({
        html: "Está seguro de cancelar esta reserva  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/cancelar-reserva");
          this.requestApi({
            method: "POST",
            data: {
              DETALLE_ID: item.DETALLE_ID,
            },
          }).then((res) => {
            this.alertNotification({ param: { html: res.data.msg } });
            this.cargarGuias();
          });
        }
      });
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("RESERVA DE CARGA");
    this.getDestinos();
    this.cargarGuias();
  },
};
</script>
